import React, {useEffect, useRef, useState} from 'react';
import anime from 'animejs';
import './App.css';
import Contact from "./Contact";
import Github from "./Github";
import autoAnimate from "@formkit/auto-animate";
import {Alert, Collapse, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

function App() {

  const parent = useRef(null);

  useEffect(() => {
        anime({
            targets:"#b1",
            translateY: -50,
            delay: 500,
        });
        anime({
            targets:"#b2",
            translateY: -96,
            delay: 500,
        });
        anime({
            targets:"#b3",
            translateY: -142,
            delay: 500,
        });
        anime({
            targets:"#b4",
            translateY: -96,
            delay: 500,
        });
        anime({
            targets:"#b5",
            translateY: -50,
            delay: 500,
        });


      parent.current && autoAnimate(parent.current)
  }, []);

  const [showContact, setShowContact] = useState(false)
  const [showToast, setShowToast] = useState(false);



  return (
    <div className="App">
           <div className="boxContainer">
               <div id="b1" className="box"></div>
               <div id="b2" className="box"></div>
               <div id="b3" className="box"></div>
               <div id="b4" className="box"></div>
               <div id="b5" className="box"></div>
           </div>
        <div ref={parent}  >
            &nbsp;
                <Github isActive={!showContact}></Github> &nbsp;
                <Contact onSuccess={() => {setShowContact(false);setShowToast(true);}}
                         onCancel={() => {setShowContact(false);}}
                         onActivate={() =>{setShowToast(false); setShowContact(true);}}
                         isActive={showContact}></Contact>
        </div>

        <Collapse in={showToast}>
            <Alert
                action={
                  <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowToast(false);
                      }}
                  >
                      <Close fontSize="inherit" />
                  </IconButton>
                }
                style={{width: "235px", margin: "0 auto"}} severity="success">We'll be in touch shortly!</Alert>
        </Collapse>
    </div>
  );
}

export default App;
