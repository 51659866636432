import React, {ChangeEvent, FormEvent, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {CancelOutlined, EmailSharp, Send} from "@mui/icons-material";
import {Button} from "@mui/material";
import './Contact.css';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
  onActivate: () => void;
  isActive: boolean
}

const defaultValues = {
  name: "",
  email: "",
  message: "",
};

function Contact(props: Props) {

  const [formValues, setFormValues] = useState(defaultValues);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const encode = (data: any) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log(formValues);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formValues})
      })
        .then(() => {})
        .catch(error => {});
    formValues.name = "";
    formValues.email = "";
    formValues.message = "";

    props.onSuccess();
  };




  if (!props.isActive) {
    return (
        <a className="navMenu" href="#" onClick={props.onActivate}><EmailSharp fontSize="large"></EmailSharp></a> );
  }

  return (
     <div className="lightblue">
       <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center" justifyContent="center" direction="column">
        <Grid item>
          <TextField
            id="name-input"
            name="name"
            label="Name"
            type="text"
            value={formValues.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <TextField
            id="email-input"
            name="email"
            label="E-Mail"
            type="text"
            value={formValues.email}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <TextField
            multiline
            minRows={5}
            id="message-input"
            name="message"
            label="Message"
            type="text"
            value={formValues.message}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={0.5} alignItems="center" justifyContent="center" >

            <Grid item xs={5}>
              <Button onClick={props.onCancel} variant="outlined" startIcon={<CancelOutlined />}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={1}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Grid>
            <Grid item xs={5}>
              <Button type="submit" variant="contained" endIcon={<Send />}>
                Send
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

     </form>
    </div>
  );
}

export default Contact;