import {GitHub} from "@mui/icons-material";


interface Props {
    isActive: boolean;
}

function Github(props: Props) {

    return (
        <span className="navMenu">{props.isActive ? (<a href="https://github.com/akiscode" target="_blank" rel="noopener noreferrer"><GitHub fontSize="large"></GitHub> </a>): (<span></span>) }</span>
    );
}

export default Github;